<script>
    export let sites = [];
    export let simple = false;
    export let bw;
    export let path = '/';
    export let showCustomerServiceLink = false;
</script>

<ul aria-label="Noen publikasjoner som bruker aID" class="{simple ? '' : 'rich'} {bw ? 'bw' : ''}">
    {#each sites as site}
        <li>
            <a href="https://{site.domains.main}{path}" aria-label="Gå til forsiden av {site.name?.full}">
                <img src="https://r.api.no/local/v3/publications/{site.domains?.main}/gfx/small-positive.svg" alt="" />
                {#if !simple}
                    <h2 class="site-name">{site.name?.full}</h2>
                {/if}
            </a>
            {#if showCustomerServiceLink && site.config.aid.legal_entity === 'amedia'}
                <a href="//{site.domains.main}/abo/">Kundesenter</a>
            {/if}
        </li>
    {/each}
</ul>

<style>
    @import '../common/colors.css';
    
    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 40px;
    }

    ul h2 {
        border-bottom: 0;
    }

    ul li {
        box-sizing: border-box;
        flex-grow: 0;
        border-top: 3px solid var(--lightGray);
        border-left: 3px solid var(--lightGray);
        list-style: none;
        height: 100px;
        margin: 0;
        width: 25%;
        display: flex;
    }

    ul li a {
        position: relative;
    }

    ul.rich li {
        flex-direction: column;
    }

    ul li a:first-of-type {
        padding-top: 30px;
        flex: 1;
    }

    ul li a:last-of-type {
        padding-bottom: 30px;
    }

    ul li a:last-of-type:not(:first-of-type){
        padding: 7px;
    }

    ul li a > * {
        color: inherit;
    }

    ul li:hover {
        background: var(--lightGray);
    }

    ul.rich li:hover {
        background: transparent;
    }

    ul.rich li {
        height: auto;
        text-align: center;
    }


    ul img {
        height: 100%;
        max-width: 80%;
        margin: 0 auto;
        display: block;
    }

    ul.rich img {
        height: 80px;
        max-width: 100px;
    }

    ul.bw img {
        filter: brightness(0) grayscale(1);
    }

    ul li:nth-child(1),
    ul li:nth-child(2),
    ul li:nth-child(3),
    ul li:nth-child(4) {
        border-top: 0 none;
    }

    ul li:nth-child(4n+1) {
        border-left: 0 none;
    }

    @media screen and (max-width: 1000px) {
        ul li {
            width: 33.333%;
        }

        ul li:nth-child(4) {
            border-top: 3px solid var(--lightGray);
        }

        ul li:nth-child(4n+1) {
            border-left: 3px solid var(--lightGray);
        }

        ul li:nth-child(3n+1) {
            border-left: 0 none;
        }
    }

    @media screen and (max-width: 750px) {
        ul li {
            width: 50%;
        }

        ul li:nth-child(3),
        ul li:nth-child(4) {
            border-top: 3px solid var(--lightGray);
        }

        ul li:nth-child(4n+1),
        ul li:nth-child(3n+1) {
            border-left: 3px solid var(--lightGray);
        }

        ul li:nth-child(2n+1) {
            border-left: 0 none;
        }
    }

    ul .site-name {
        margin: 0;
        padding-top: 10px;
        font-size: 16px;
    }
</style>